import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { writeStorage } from '@rehooks/local-storage';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Error } from '../../Components/Error/Error';
import { httpClient } from '../../Interceptors/httpClient';

export const ChangePassword: React.FC<{}> = () => {
  const [error, setError] = useState<string | null>(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old password is required'),
      newPassword: Yup.string().required('New password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: async (values) => {
      setError('');
      try {
        const response = await httpClient.post(
          '/identity/changeLoggedInUserPassword',
          {
            currentPassword: values.oldPassword,
            newPassword: values.newPassword,
          }
        );
        if (response.status === 200) {
          writeStorage('token', response.data.token);
          writeStorage('refreshToken', response.data.refreshToken);
          setPasswordChanged(true);
        }
      } catch (e) {
        setError('An error occurred while changing the password.');
      }
    },
  });

  return !passwordChanged ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        component={'form'}
        sx={{
          m: 1,
          width: 250,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
            variant="h3"
            component="h3"
          >
            Change Password
          </Typography>
          <TextField
            label="Old Password"
            name="oldPassword"
            type="password"
            variant="filled"
            margin="normal"
            disabled={formik.isSubmitting}
            error={!!formik.errors.oldPassword && formik.touched.oldPassword}
            helperText={
              !!formik.errors.oldPassword && formik.touched.oldPassword
                ? formik.errors.oldPassword
                : ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
          />
          <TextField
            label="New Password"
            name="newPassword"
            type="password"
            variant="filled"
            margin="normal"
            disabled={formik.isSubmitting}
            error={!!formik.errors.newPassword && formik.touched.newPassword}
            helperText={
              !!formik.errors.newPassword && formik.touched.newPassword
                ? formik.errors.newPassword
                : ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            variant="filled"
            margin="normal"
            disabled={formik.isSubmitting}
            error={
              !!formik.errors.confirmPassword && formik.touched.confirmPassword
            }
            helperText={
              !!formik.errors.confirmPassword && formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ''
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Loading...' : 'Submit'}
          </Button>
          <Box
            sx={{
              textAlign: 'center',
              color: 'red',
            }}
          >
            {error && <Error error={error} />}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box>
        <Typography sx={{ textAlign: 'center' }} variant="h4">
          Success!
        </Typography>
        <Alert severity="success">Your password has been changed.</Alert>
      </Box>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        onClick={() => {
          navigate('/');
        }}
      >
        Home
      </Button>
    </Box>
  );
};
