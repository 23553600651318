import { createContext } from 'react';
import { User } from '../Models/Auth/user.model';

export const UserContext = createContext<{
  user: User | undefined;
  setUser: (user: User | undefined) => void;
}>({
  user: undefined,
  setUser: () => undefined,
});
