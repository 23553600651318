import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { deleteFromStorage, useLocalStorage } from '@rehooks/local-storage';
import { useQuery } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { useMemo, useState } from 'react';
import './App.scss';
import { ContentsWrapper } from './Components/Navbar/Navbar';
import { UserContext } from './Contexts/UserContext';
import { LocationProvider } from './Contexts/UserLocationContext';
import { useTheme } from './Hooks/use-theme';
import { httpClient } from './Interceptors/httpClient';
import { User } from './Models/Auth/user.model';
import { darkTheme } from './Themes/dark-theme';
import { lightTheme } from './Themes/light-theme';

const App = (): JSX.Element => {
  const { theme } = useTheme();
  const [token] = useLocalStorage('token');
  const [user, setUser] = useState<User | undefined>(undefined);

  const currentTheme = useMemo(
    () => (theme === 'dark' ? darkTheme : lightTheme),
    [theme]
  );

  const fetchUser = async () => {
    const response = await httpClient.get<User>('identity/me');
    setUser(response.data);
    return response.data;
  };

  useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    enabled: !!token, // The query will run automatically if a token is present
    refetchInterval: 1000 * 60 * 5, // Refetch every 5 minutes
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: 1,
    throwOnError: (error, query) => {
      deleteFromStorage('token');
      deleteFromStorage('refreshToken');
      setUser(undefined);
      return false;
    },
  });

  const value = useMemo(
    () => ({
      user,
      setUser: (newUser: User | undefined) => setUser(newUser),
    }),
    [user]
  );

  return (
    <ThemeProvider theme={currentTheme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <div className="app">
          <LocationProvider>
            <UserContext.Provider value={value}>
              <ContentsWrapper />
            </UserContext.Provider>
          </LocationProvider>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
