import { alpha, createTheme } from '@mui/material';
import { neumorphicShadow } from './neumorphicShadow';

const primaryColor = '#61AFEF';
const secondaryColor = '#C677DE';
const warningColor = '#E5C07B';
const errorColor = '#E06C75';
const successColor = '#97C379';
const textPrimaryColor = '#ffffff';
const textSecondaryColor = '#a0a0a0';
const backgroundDefault = '#181A1F';
const backgroundPaper = '#24252B';
const shadowColor = '#000000';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: backgroundDefault,
      paper: backgroundPaper,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            boxShadow: 'none',
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor),
          '&:hover': {
            boxShadow: neumorphicShadow(shadowColor, true),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor, true),
          borderRadius: 8,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          '& .MuiInputBase-root': {
            boxShadow: neumorphicShadow(shadowColor, true),
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: backgroundPaper,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${alpha(textPrimaryColor, 0.12)}`,
        },
        head: {
          backgroundColor: backgroundPaper,
        },
        body: {
          backgroundColor: backgroundPaper,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: backgroundPaper,
          color: textPrimaryColor,
          boxShadow: neumorphicShadow(shadowColor),
        },
      },
    },
  },
});
