import {
  AccountCircle,
  ExpandLess,
  Logout,
  Password,
  Person,
} from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import useLocalStorage, { deleteFromStorage } from '@rehooks/local-storage';
import { useContext, useEffect, useRef, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import { useTheme } from '../../Hooks/use-theme';
import { Token } from '../../Models/Auth/token.model';
import { ProfileDialog } from './ProfileDialog';
import { UserMenuProps } from './UserMenuProps';

export const UserMenu = ({ handleUserNavigation }: UserMenuProps) => {
  const { user, setUser } = useContext(UserContext);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [token] = useLocalStorage('token');
  const decodedToken = decodeToken<Token>(token || '');
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const moreAnchorRef = useRef<HTMLLIElement>(null);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const { theme, setTheme } = useTheme();

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      (anchorRef.current &&
        anchorRef.current.contains(event?.target as HTMLElement)) ||
      (moreAnchorRef.current &&
        moreAnchorRef.current.contains(event?.target as HTMLElement))
    ) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleProfile = (event: MouseEvent | TouchEvent) => {
    setIsProfileDialogOpen(true);
    handleClose(event);
    handleUserNavigation();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleChangePassword = (event: MouseEvent | TouchEvent) => {
    navigate('/change-password');
    handleClose(event);
    handleUserNavigation();
  };

  const logOut = () => {
    deleteFromStorage('token');
    deleteFromStorage('refreshToken');
    setUser(undefined);
    navigate('/');
    handleUserNavigation();
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
        onClick={handleToggle}
      >
        {user.profileImage && (
          <Box
            ref={anchorRef as any}
            src={`${user?.profileImage}`}
            alt="profile"
            sx={{
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              border: (theme) => `2px solid ${theme.palette.divider}`,
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundSize: 'cover',
              cursor: 'pointer',
            }}
            component={'img'}
          ></Box>
        )}
        {!user.profileImage && (
          <AccountCircle
            ref={anchorRef as any}
            sx={{
              height: '50px',
              width: '50px',
              borderRadius: '50%',
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundSize: 'cover',
              cursor: 'pointer',
            }}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 1,
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              ml: 1,
            }}
          >
            {fullName}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              ml: 1,
              color: 'text.secondary',
            }}
          >
            {decodedToken?.email}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 'auto',
            mr: 1,
          }}
        >
          <ExpandLess
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}
          />
        </Box>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    pt: 0,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      pt: 1,
                      pb: 1,
                      pl: 2,
                      color: 'text.secondary',
                      display: 'block',
                    }}
                  >
                    {decodedToken?.email}
                  </Typography>
                  <MenuItem onClick={(event) => handleProfile(event as any)}>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText primary={'Profile'} />
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      setTheme(theme === 'dark' ? 'light' : 'dark')
                    }
                  >
                    <ListItemIcon>
                      {theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => handleChangePassword(event as any)}
                  >
                    <ListItemIcon>
                      <Password />
                    </ListItemIcon>
                    <ListItemText primary={'Change Password'} />
                  </MenuItem>
                  <Divider sx={{ margin: '0 !important' }} />
                  <MenuItem onClick={logOut}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary={'Logout'} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ProfileDialog
        open={isProfileDialogOpen}
        handleClose={() => setIsProfileDialogOpen(false)}
      />
    </>
  );
};
