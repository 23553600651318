import { Box, Icon } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { FavoriteIconProps } from './FavoriteIconProps';

const colors = [
  '#FF00FF',
  '#FF69B4',
  '#FFD700',
  '#ADFF2F',
  '#00FFFF',
  '#FF4500',
  '#9400D3',
  '#00FF00',
  '#FF1493',
  '#1E90FF',
];

const animateSparkle = (sparkle: HTMLElement, x: number, y: number) => {
  const duration = 600;
  const start = performance.now();

  const step = (timestamp: number) => {
    const progress = (timestamp - start) / duration;

    if (progress < 1) {
      sparkle.style.transform = `translate(${x * progress}px, ${y * progress}px) scale(${1 - 0.5 * progress})`;
      sparkle.style.opacity = `${1 - progress}`;
      requestAnimationFrame(step);
    } else {
      sparkle.style.transform = `translate(${x}px, ${y}px) scale(0.5)`;
      sparkle.style.opacity = '0';
    }
  };

  requestAnimationFrame(step);
};

const createSparkles = (iconElement: HTMLElement) => {
  const sparklesContainer = document.createElement('div');
  sparklesContainer.className = 'sparkles-container';
  // Make sparkles container overlay the icon
  sparklesContainer.style.position = 'absolute';
  sparklesContainer.style.top = '0';
  sparklesContainer.style.left = '0';
  sparklesContainer.style.width = '100%';
  sparklesContainer.style.pointerEvents = 'none';
  iconElement.appendChild(sparklesContainer);

  for (let i = 0; i < 20; i++) {
    const sparkle = document.createElement('div');
    sparkle.className = 'sparkle';
    sparkle.style.backgroundColor =
      colors[Math.floor(Math.random() * colors.length)];
    sparklesContainer.appendChild(sparkle);

    // Set initial position to the center of the container
    sparkle.style.left = '50%';
    sparkle.style.top = '50%';
    sparkle.style.transform = 'translate(-50%, -50%)';

    // Randomize the direction and distance
    const angle = Math.random() * 2 * Math.PI;
    const distance = Math.random() * 50 + 10;
    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    // Animate the sparkle
    animateSparkle(sparkle, x, y);

    // Ensure pointer-events: none on each sparkle
    sparkle.style.pointerEvents = 'none';
  }

  // Remove sparkles after animation ends
  setTimeout(() => {
    if (iconElement.contains(sparklesContainer)) {
      iconElement.removeChild(sparklesContainer);
    }
  }, 1000);
};

export const FavoriteIcon = (props: FavoriteIconProps): JSX.Element => {
  const { isFavorite, restaurantId, onClick, isUpdating } = props;
  const iconSize = 28;
  const ref = useRef<HTMLSpanElement | null>(null);
  const [triggerSparkle, setTriggerSparkle] = useState(false);
  const { user } = useContext(UserContext);
  const [loggedInConfirmDialogOpen, setLoggedInConfirmDialogOpen] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (triggerSparkle && ref.current && !isFavorite) {
      createSparkles(ref.current);
      setTriggerSparkle(false);
    }
  }, [triggerSparkle, isFavorite]);

  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>
  ) => {
    event.stopPropagation();
    if (!user) {
      setLoggedInConfirmDialogOpen(true);
      return;
    }
    if (!isFavorite) {
      setTriggerSparkle(true);
    }
    onClick?.(event, restaurantId);
  };

  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return (
    <Box
      sx={{
        ...props.sx,
        position: 'relative',
      }}
    >
      <Icon
        component="span"
        ref={ref}
        sx={{
          fontSize: iconSize,
          color: isFavorite || isUpdating ? 'secondary.main' : 'action.active',
          cursor: 'pointer',
          ...(isTouchDevice
            ? {}
            : {
                '&:hover': {
                  color: (theme) =>
                    isFavorite
                      ? theme.palette.secondary.dark
                      : theme.palette.secondary.main,
                },
              }),
        }}
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {isUpdating ? 'favorite' : isFavorite ? 'favorite' : 'favorite_border'}
      </Icon>
      <ConfirmDialog
        id="favorite-user-login-dialog"
        title="Login required"
        message="You must be logged in to favorite a restaurant."
        onConfirm={() => {
          setLoggedInConfirmDialogOpen(false);
          navigate('/login');
        }}
        onCancel={(event) => {
          event.stopPropagation();
          setLoggedInConfirmDialogOpen(false);
        }}
        open={loggedInConfirmDialogOpen}
        confirmButtonText="Sign In/Up"
      />
    </Box>
  );
};
