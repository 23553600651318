import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Error } from '../../../Components/Error/Error';
import { ZoomTransition } from '../../../Components/RestaurantDialog/ZoomTransition';
import { httpClient } from '../../../Interceptors/httpClient';
import { User } from '../../../Models/Auth/user.model';
export interface SimpleDialogProps {
  open: boolean;
  onClose: (user?: User) => void;
  user: User | undefined;
}

export const UserModifyDialog = (props: SimpleDialogProps) => {
  const { onClose, open, user } = props;
  const [error, setError] = useState<AxiosError | undefined | string>(
    undefined
  );
  const [selectedProfileImage, setSelectedProfileImage] = useState<
    string | undefined
  >(undefined);

  const validationSchema = Yup.object().shape({
    roles: Yup.array().required('Roles are required'),
    lockoutEnabled: Yup.boolean().required('Lockout is required'),
  });

  const handleClose = (user?: User) => {
    if (formik.isSubmitting) {
      return;
    }
    onClose(user);
    setTimeout(() => {
      formik.resetForm();
      setError(undefined);
      setSelectedProfileImage(undefined);
    }, 200);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roles: user?.roles ?? [],
      lockoutEnabled: user?.lockoutEnabled ?? false,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      profileImage: user?.profileImage ?? '',
      fileName: user?.profileImageFileName ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(undefined);
      try {
        // Submit the form to the server
        const response = await httpClient.post('/identity/updateUser', {
          lockoutEnabled: values.lockoutEnabled,
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
          userId: user?.id,
          image: values.profileImage,
          fileName: values.fileName,
        });
        if (response.status === 200) {
          formik.isSubmitting = false;
          handleClose(response.data as User);
        }
      } catch (error: any) {
        setError(error ?? 'An error occurred while updating the user.');
      }
    },
  });

  const handleRolesChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string[]
  ) => {
    formik.setFieldValue('roles', value);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    // Validate the file is an image
    if (!file?.type.startsWith('image/')) {
      setError('The file must be an image.');
      return;
    }
    if (file) {
      // get bytes from file and convert them into base64
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const profileImageResult = reader.result as string;
        // Set the selected profile image
        setSelectedProfileImage(profileImageResult);
        formik.setFieldValue('profileImage', reader.result as string);
        formik.setFieldValue('fileName', file.name);
        formik.setFieldValue('imageType', file.type);
      };
    }
  };

  return (
    <Dialog
      TransitionComponent={ZoomTransition}
      onClose={() => handleClose()}
      open={open}
      maxWidth="xl"
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '300px',
        },
      }}
    >
      <DialogTitle>Edit User</DialogTitle>
      <Divider />
      <DialogContent>
        <FormGroup
          sx={{
            alignItems: 'center',
            display: 'flex !important',
            flexDirection: 'row',
            mb: 2,
          }}
        >
          {(formik.values.profileImage || selectedProfileImage) && (
            <img
              src={`${selectedProfileImage ?? formik.values.profileImage}`}
              alt="profile"
              style={{
                height: '75px',
                marginRight: '1rem',
              }}
            />
          )}
          {!formik.values.profileImage && !selectedProfileImage && (
            <AccountCircle
              sx={{
                fontSize: 75,
                mr: 1,
              }}
            />
          )}
          {!formik.values.profileImage && !selectedProfileImage && (
            <Box
              sx={{
                mr: 1,
              }}
            >
              <input
                title="Add Profile Image"
                style={{ display: 'none' }}
                id="add-button-file"
                onChange={handleFileChange}
                type="file"
                accept="image/*"
              />
              <label style={{}} htmlFor="add-button-file">
                <Button variant="contained" color="secondary" component="span">
                  add
                </Button>
              </label>
            </Box>
          )}
          {(formik.values.profileImage || selectedProfileImage) && (
            <>
              <Box
                sx={{
                  mr: 1,
                }}
              >
                <input
                  title="Change Profile Image"
                  style={{ display: 'none' }}
                  id="change-button-file"
                  onChange={handleFileChange}
                  type="file"
                  accept="image/*"
                />
                <label style={{}} htmlFor="change-button-file">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    Change
                  </Button>
                </label>
              </Box>
              <Button
                sx={{
                  mr: 1,
                }}
                variant="contained"
                color="error"
                onClick={() => {
                  setSelectedProfileImage(undefined);
                  formik.setFieldValue('profileImage', '');
                  formik.setFieldValue('fileName', '');
                }}
              >
                Remove
              </Button>
            </>
          )}
        </FormGroup>
        <Typography variant="caption" gutterBottom component="label">
          Email
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          {user?.email}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Typography variant="caption" gutterBottom component="label">
              Lockout Enabled
            </Typography>
            <Switch
              checked={formik.values.lockoutEnabled}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              name="lockoutEnabled"
            />
            <TextField
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              name="firstName"
              margin="normal"
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              name="lastName"
              margin="normal"
              variant="outlined"
              fullWidth={true}
            />
            <Typography variant="caption" gutterBottom component="label">
              Roles
            </Typography>
            <ToggleButtonGroup
              value={formik.values.roles}
              onChange={handleRolesChange}
              disabled={formik.isSubmitting}
              aria-label="Roles"
            >
              <ToggleButton value="Admin" aria-label="Admin" name="roles">
                Admin
              </ToggleButton>
              <ToggleButton value="Basic" aria-label="Basic" name="roles">
                Basic
              </ToggleButton>
            </ToggleButtonGroup>
          </FormGroup>
        </form>
        {error && <Error error={error} />}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          color="inherit"
          variant="outlined"
          disabled={formik.isSubmitting}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!formik.isValid || formik.isSubmitting}
          onClick={formik.submitForm}
        >
          {formik.isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
