import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import {
  RestaurantAutocomplete,
  RestaurantAutocompleteRef,
} from '../../../Components/RestaurantAutocomplete/RestaurantAutocomplete';
import { httpClient } from '../../../Interceptors/httpClient';
import { HappyHourByZipForm } from './HappyHourByZipForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-happy-hour-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `admin-happy-hour-tab-${index}`,
    'aria-controls': `admin-happy-hour-tabpanel-${index}`,
  };
}

export const AdminHappyHour = (): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const autocompleteRef = React.useRef<RestaurantAutocompleteRef>(null);

  const handleClear = () => {
    autocompleteRef?.current?.clear();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="By Zip" {...a11yProps(0)} />
          <Tab label="By Restaurant" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <HappyHourByZipForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="h6" component="div" gutterBottom>
          Select a restaurant to queue for happy hour updates.
        </Typography>
        <RestaurantAutocomplete
          bypassLocation={true}
          ref={autocompleteRef}
          onSelect={async (restaurant) => {
            // Send api request to queue happy hour updates for restaurant
            try {
              await httpClient.post(`/happyHour/restaurant/${restaurant.id}`);
              enqueueSnackbar('Happy hour updates queued.', {
                variant: 'success',
              });
            } catch (error) {
              error instanceof AxiosError
                ? enqueueSnackbar(error.message, {
                    variant: 'error',
                  })
                : enqueueSnackbar('An unknown error occurred.', {
                    variant: 'error',
                  });
            } finally {
              // Clear the selected restaurant
              handleClear();
            }
          }}
        />
      </TabPanel>
    </Box>
  );
};
