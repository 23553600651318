import { Box, Button, Tab, Tabs, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { ConfirmDialog } from '../../../Components/ConfirmDialog/ConfirmDialog';
import { ZipCodeMultiSelect } from '../../../Components/ZipCodeMultiSelect/ZipCodeMultiSelect';
import { httpClient } from '../../../Interceptors/httpClient';
import { ZipCodeByAreaTab } from './ZipCodeByAreaTab';

export const RestaurantZipQueuePage: FC = () => {
  const [disabled, setDisabled] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState<number>(0);

  const triggerRefresh = () => {
    setRefreshData(!refreshData);
  };

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const initialValues = {
    zipCodes: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      zipCodes: Yup.array()
        .required('Required')
        .min(1, 'Must select at least one zip code'),
    }),
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setDisabled(true);
        await httpClient.post('restaurant/zip', values.zipCodes);
        enqueueSnackbar('Restaurant zip codes added to queue', {
          variant: 'success',
        });
        // Reset the form
        formik.resetForm();
        // Refresh the data
        triggerRefresh();
      } catch (error) {
        const axiosError = error as AxiosError;
        enqueueSnackbar(axiosError.message || 'An error occurred', {
          variant: 'error',
        });
      } finally {
        setDisabled(false);
      }
    },
  });

  return (
    <>
      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
        <Tab label="By Area" />
        <Tab label="By Zip Codes" />
      </Tabs>
      {tabValue === 0 && <ZipCodeByAreaTab />}
      {tabValue === 1 && (
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            flexDirection: isSmallScreen ? 'column-reverse' : 'row',
          }}
        >
          <form
            style={{
              width: '100%',
              padding: '1rem',
              maxWidth: isSmallScreen ? '100%' : '500px',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <ZipCodeMultiSelect
                value={formik.values.zipCodes}
                onChange={(value) => formik.setFieldValue('zipCodes', value)}
                name="zipCodes"
                disabled={disabled || formik.isSubmitting}
              />
              <Button
                disabled={!formik.isValid || formik.isSubmitting || disabled}
                variant="contained"
                type="submit"
              >
                {formik.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
              <Button
                disabled={formik.isSubmitting || disabled}
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Queue all zip codes
              </Button>
            </Box>
          </form>
          <ConfirmDialog
            id="confirmDialog"
            title="Confirm"
            message="Are you sure you want to queue all zip codes? You should almost never do this."
            onConfirm={async () => {
              // Close the dialog
              setOpen(false);
              try {
                setDisabled(true);
                await httpClient.post('restaurant/zip/all');
              } catch (error) {
                const axiosError = error as AxiosError<{
                  message: string;
                }>;
                const data = axiosError.response?.data;
                enqueueSnackbar(data?.message || 'An error occurred', {
                  variant: 'error',
                });
              } finally {
                setDisabled(false);
              }
            }}
            onCancel={() => {
              setOpen(false);
            }}
            open={open}
          />
        </Box>
      )}
    </>
  );
};
