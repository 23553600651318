import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { ConfirmDialog } from '../../../Components/ConfirmDialog/ConfirmDialog';
import { Error } from '../../../Components/Error/Error';
import { httpClient } from '../../../Interceptors/httpClient';
import { AddCuisineTypeDialogProps } from './AddCuisineTypeDialogProps';

export const CuisineTypeAdmin: FC = () => {
  const [selectedCuisineTypeId, setSelectedCuisineTypeId] = useState<
    string | null
  >(null);
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['cuisineType'],
    queryFn: async () => {
      const response = await httpClient.get('cuisineType');
      return response.data;
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      id: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await httpClient.put(`cuisineType/${selectedCuisineTypeId}`, values);
        refetch();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const response = errorResponse.response as AxiosResponse;
        enqueueSnackbar(response.data, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'initial',
      }}
    >
      {isLoading && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {[1, 2, 3, 4, 5].map((value) => {
            return (
              <Box key={value}>
                <Skeleton variant="rectangular" width={300} height={80} />
                <Divider />
              </Box>
            );
          })}
        </Box>
      )}
      {error && <Error error={error.message} />}
      {data && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={formik.isSubmitting}
              >
                Add Cuisine Type
              </Button>
            </Box>
            <Divider />
            {data.length === 0 && (
              <Box sx={{ p: 1, width: 300, textAlign: 'center' }}>
                <Typography>No cuisine types found</Typography>
              </Box>
            )}
            {data.length > 0 && (
              <List
                sx={{
                  width: 350,
                  backgroundColor: (theme) => theme.palette.background.paper,
                }}
              >
                {data.map((cuisineType: any) => (
                  <ListItemButton
                    divider
                    key={cuisineType.id}
                    onClick={() => {
                      setSelectedCuisineTypeId(cuisineType.id);
                      formik.setValues({
                        name: cuisineType.name,
                        description: cuisineType.description,
                        id: cuisineType.id,
                      });
                    }}
                    selected={cuisineType.id === selectedCuisineTypeId}
                    disabled={formik.isSubmitting}
                  >
                    <ListItemText
                      primary={cuisineType.name}
                      secondary={cuisineType.description}
                      secondaryTypographyProps={{
                        noWrap: true,
                      }}
                    />
                    <IconButton
                      onClick={async (e) => {
                        e.stopPropagation();
                        setConfirmDialogOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemButton>
                ))}
              </List>
            )}
          </Box>
          {selectedCuisineTypeId && (
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ p: 1 }}>
                    <TextField
                      sx={{ width: 300 }}
                      id="name"
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <TextField
                      sx={{ width: 300 }}
                      id="description"
                      name="description"
                      label="Description"
                      type="text"
                      multiline
                      rows={8}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      )}
      <AddCuisineTypeDialog
        open={open}
        onClose={() => setOpen(false)}
        onAdd={refetch}
      />
      <ConfirmDialog
        id="confirmDialog"
        title="Delete Cuisine Type"
        message="Are you sure you want to delete this cuisine type?"
        open={confirmDialogOpen}
        onCancel={() => setConfirmDialogOpen(false)}
        onConfirm={async () => {
          setIsDeleting(true);
          try {
            await httpClient.delete(`cuisineType/${selectedCuisineTypeId}`);
            refetch();
            setConfirmDialogOpen(false);
            setSelectedCuisineTypeId(null);
            formik.resetForm();
          } catch (error) {
            const errorResponse = error as AxiosError;
            const response = errorResponse.response as AxiosResponse;
            enqueueSnackbar(
              response.data ??
                'An unknown error occurred while deleting the cuisine type',
              {
                variant: 'error',
              }
            );
          }
          setIsDeleting(false);
        }}
        disabled={isDeleting}
      />
    </Box>
  );
};

export const AddCuisineTypeDialog = (props: AddCuisineTypeDialogProps) => {
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 100);
    }
  }, [props.open]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await httpClient.post('cuisineType', values);
        formik.resetForm();
        props.onAdd();
        props.onClose();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const response = errorResponse.response as AxiosResponse;
        enqueueSnackbar(response.data, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Box>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle id="confirmDialogLabel">Add Cuisine Type</DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <TextField
              sx={{ width: 300 }}
              inputRef={nameInputRef}
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              disabled={formik.isSubmitting}
            />
            <TextField
              sx={{ width: 300 }}
              id="description"
              name="description"
              label="Description"
              type="text"
              multiline
              rows={8}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              disabled={formik.isSubmitting}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
            color="inherit"
            variant="outlined"
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
