import { Box, Button, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';
import { ZipCodeMultiSelect } from '../../../Components/ZipCodeMultiSelect/ZipCodeMultiSelect';
import { httpClient } from '../../../Interceptors/httpClient';

export const HappyHourByZipForm = () => {
  const [disabled, setDisabled] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const initialValues = {
    zipCodes: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      zipCodes: Yup.array()
        .required('Required')
        .min(1, 'Must select at least one zip code'),
    }),
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setDisabled(true);
        await httpClient.post('happyHour/zip-codes', values.zipCodes);
        enqueueSnackbar('Happy hour zip codes added to queue', {
          variant: 'success',
        });
        // Reset the form
        formik.resetForm();
      } catch (error) {
        const axiosError = error as AxiosError;
        enqueueSnackbar(axiosError.message || 'An error occurred', {
          variant: 'error',
        });
      } finally {
        setDisabled(false);
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
        flexDirection: isSmallScreen ? 'column-reverse' : 'row',
      }}
    >
      <form
        style={{
          width: '100%',
          padding: '1rem',
          maxWidth: isSmallScreen ? '100%' : '500px',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <ZipCodeMultiSelect
            value={formik.values.zipCodes}
            onChange={(value) => formik.setFieldValue('zipCodes', value)}
            name="zipCodes"
            disabled={disabled || formik.isSubmitting}
          />
          <Button
            variant="contained"
            disabled={disabled || !formik.isValid || formik.isSubmitting}
            type="submit"
          >
            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
