import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';

export interface ConfirmDialogProps {
  id: string;
  title: string;
  message: string;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  disabled?: boolean;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const { id, title, message, onConfirm, onCancel, open } = props;

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onCancel(event);
  };

  const handleConfirm = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onConfirm(event);
  };

  return (
    <div
      id={id}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmDialogLabel"
      aria-hidden="true"
    >
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle id="confirmDialogLabel">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancel}
            color="inherit"
            variant="outlined"
            disabled={props.disabled}
          >
            {props.cancelButtonText ?? 'Cancel'}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            disabled={props.disabled}
          >
            {props.confirmButtonText ?? 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
