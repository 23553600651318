import { Box, Tooltip } from '@mui/material';
import 'iconify-icon';

export const AmericanCuisine = () => (
  <iconify-icon icon="mdi:hamburger"></iconify-icon>
);
export const ItalianCuisine = () => (
  <iconify-icon icon="mdi:pasta"></iconify-icon>
);
export const MexicanCuisine = () => (
  <iconify-icon icon="mdi:taco"></iconify-icon>
);
export const ChineseCuisine = () => (
  <iconify-icon icon="mdi:noodles"></iconify-icon>
);
export const JapaneseCuisine = () => (
  <iconify-icon icon="mdi:fish"></iconify-icon>
);
export const IndianCuisine = () => (
  <iconify-icon icon="mdi:rice"></iconify-icon>
);
export const FrenchCuisine = () => (
  <iconify-icon icon="mdi:food-croissant"></iconify-icon>
);
export const ThaiCuisine = () => (
  <iconify-icon icon="mdi:bowl-mix"></iconify-icon>
);
export const GreekCuisine = () => <iconify-icon icon="mdi:gyro"></iconify-icon>;
export const MediterraneanCuisine = () => (
  <iconify-icon icon="mdi:food-variant"></iconify-icon>
);
export const VietnameseCuisine = () => (
  <iconify-icon icon="mdi:rice-bowl"></iconify-icon>
);
export const KoreanCuisine = () => (
  <iconify-icon icon="mdi:kimchi"></iconify-icon>
);
export const SpanishCuisine = () => (
  <iconify-icon icon="mdi:food-fork-drink"></iconify-icon>
);
export const CaribbeanCuisine = () => (
  <iconify-icon icon="mdi:fruit-pineapple"></iconify-icon>
);
export const MiddleEasternCuisine = () => (
  <iconify-icon icon="mdi:falafel"></iconify-icon>
);
export const BrazilianCuisine = () => (
  <iconify-icon icon="mdi:food-steak"></iconify-icon>
);
export const EthiopianCuisine = () => (
  <iconify-icon icon="mdi:coffee"></iconify-icon>
);
export const CajunCreoleCuisine = () => (
  <iconify-icon icon="mdi:pot-mix"></iconify-icon>
);
export const PeruvianCuisine = () => (
  <iconify-icon icon="mdi:fish"></iconify-icon>
);
export const TurkishCuisine = () => (
  <iconify-icon icon="mdi:food-turkey"></iconify-icon>
);

type CuisineIconKeys =
  | 'American'
  | 'Italian'
  | 'Mexican'
  | 'Chinese'
  | 'Japanese'
  | 'Indian'
  | 'French'
  | 'Thai'
  | 'Greek'
  | 'Mediterranean'
  | 'Vietnamese'
  | 'Korean'
  | 'Spanish'
  | 'Caribbean'
  | 'MiddleEastern'
  | 'Brazilian'
  | 'Ethiopian'
  | 'Cajun/Creole'
  | 'Peruvian'
  | 'Turkish';

const iconComponents: Record<CuisineIconKeys, React.FC> = {
  American: AmericanCuisine,
  Italian: ItalianCuisine,
  Mexican: MexicanCuisine,
  Chinese: ChineseCuisine,
  Japanese: JapaneseCuisine,
  Indian: IndianCuisine,
  French: FrenchCuisine,
  Thai: ThaiCuisine,
  Greek: GreekCuisine,
  Mediterranean: MediterraneanCuisine,
  Vietnamese: VietnameseCuisine,
  Korean: KoreanCuisine,
  Spanish: SpanishCuisine,
  Caribbean: CaribbeanCuisine,
  MiddleEastern: MiddleEasternCuisine,
  Brazilian: BrazilianCuisine,
  Ethiopian: EthiopianCuisine,
  'Cajun/Creole': CajunCreoleCuisine,
  Peruvian: PeruvianCuisine,
  Turkish: TurkishCuisine,
};

interface CuisineIconListProps {
  cuisines: string[];
}

export const CuisineIconList: React.FC<CuisineIconListProps> = ({
  cuisines,
}) => {
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {cuisines.map((cuisine, index) => {
        const IconComponent = iconComponents[cuisine as CuisineIconKeys];
        return (
          <Tooltip key={index} title={cuisine} placement="top">
            <Box component="span">
              <IconComponent />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export const AllCuisineIcons: React.FC = () => {
  const cuisineKeys: CuisineIconKeys[] = [
    'American',
    'Italian',
    'Mexican',
    'Chinese',
    'Japanese',
    'Indian',
    'French',
    'Thai',
    'Greek',
    'Mediterranean',
    'Vietnamese',
    'Korean',
    'Spanish',
    'Caribbean',
    'MiddleEastern',
    'Brazilian',
    'Ethiopian',
    'Cajun/Creole',
    'Peruvian',
    'Turkish',
  ];

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
      {cuisineKeys.map((cuisine, index) => {
        const IconComponent = iconComponents[cuisine];
        return (
          <Tooltip key={index} title={cuisine} placement="top">
            <Box component="span">
              <IconComponent />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};
