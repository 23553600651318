import {
  Close,
  ExpandLess,
  ExpandMore,
  Favorite,
  LocationOn,
  LocationSearching,
  RestaurantMenu,
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import LocalBar from '@mui/icons-material/LocalBar';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EventIcon from '@mui/icons-material/Event'; // Add this import
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useMediaQuery,
} from '@mui/material';
import { FC, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import { useIsAdmin } from '../../Hooks/is-admin';
import { UserMenu } from './UserMenu';
import InfoIcon from '@mui/icons-material/Info';

interface SideNavMenuProps {
  handleToggleClose: (closeDesktop?: boolean) => void;
}

export const SideNavMenu: FC<SideNavMenuProps> = ({ handleToggleClose }) => {
  const [openNavItem, setOpenNavItem] = useState<string>('');
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const is1024OrGreater = useMediaQuery('(min-width:1024px)');
  const { user } = useContext(UserContext);

  const handleNavClick = (route: string) => {
    navigate(route);
    handleToggleClose(!is1024OrGreater);
  };

  const handleNavCollapse = (navItem: string) => {
    if (openNavItem === navItem) {
      setOpenNavItem('');
      return;
    }
    setOpenNavItem(navItem);
  };
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
    >
      <List
        ref={scrollRef}
        sx={{
          width: 350,
          maxWidth: '100%',
          flexGrow: 1,
          position: 'relative',
          overflow: 'auto',
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              pl: 1,
              pr: 1,
            }}
            component="div"
            id="nested-list-subheader"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Happy Hour
              </Box>
              <IconButton
                aria-label="settings"
                onClick={() => {
                  handleToggleClose(true);
                }}
                sx={{
                  ml: 'auto',
                  height: 40,
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </ListSubheader>
        }
      >
        <ListItemButton
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          onClick={() => handleNavClick('/')}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItemButton>
        {user && (
          <ListItemButton
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            onClick={() => handleNavClick('/favorites')}
          >
            <ListItemIcon>
              <Favorite />
            </ListItemIcon>
            <ListItemText primary={'Favorites'} />
          </ListItemButton>
        )}
        <ListItemButton
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          onClick={() => handleNavClick('/events')}
        >
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary={'Events'} />
        </ListItemButton>
        <ListItemButton
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          onClick={() => handleNavClick('/about')}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={'About'} />
        </ListItemButton>
        {isAdmin && (
          <>
            <ListItemButton onClick={() => handleNavCollapse('Admin')}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {openNavItem === 'Admin' ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openNavItem === 'Admin'} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavClick('/admin/users')}
                >
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavClick('/admin/restaurants')}
                >
                  <ListItemIcon>
                    <RestaurantMenu />
                  </ListItemIcon>
                  <ListItemText primary="Restaurants" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavClick('/admin/zipCode')}
                >
                  <ListItemIcon>
                    <LocationOn />
                  </ListItemIcon>
                  <ListItemText primary="Insert Database Zip Codes" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavClick('/admin/restaurantZipQueue')}
                >
                  <ListItemIcon>
                    <LocationSearching />
                  </ListItemIcon>
                  <ListItemText primary="Create Restaurants by Zip" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handleNavClick('/admin/happyHour')}
                >
                  <ListItemIcon>
                    <LocalBar />
                  </ListItemIcon>
                  <ListItemText primary="Update Restaurant Happy Hour" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </List>
      <Divider />
      <List sx={{ p: 0 }}>
        {user && (
          <ListItemButton>
            <UserMenu handleUserNavigation={handleToggleClose} />
          </ListItemButton>
        )}
      </List>
    </Box>
  );
};
