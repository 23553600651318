import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <SentimentDissatisfiedIcon sx={{ fontSize: 80, mb: 2 }} />
      <Box>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          You've wandered off the beaten path.
        </Typography>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          Let's head back to where the drinks are!
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            LinkComponent={'a'}
            href="/"
            sx={{ mt: 2 }}
          >
            Nearby Deals
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
