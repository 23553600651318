import { Zoom } from '@mui/material';
import { forwardRef } from 'react';

export const ZoomTransition = forwardRef((props: any, ref: any) => {
  return (
    <Zoom direction="up" ref={ref} {...props}>
      {props.children}
    </Zoom>
  );
});
