// Custom hook using useLocalStorage and decodeToken to determine if a user has a role of admin or not.

import useLocalStorage from '@rehooks/local-storage';
import { decodeToken } from 'react-jwt';
import { Roles } from '../Models/Auth/role.model';
import { Token } from '../Models/Auth/token.model';

export const useIsAdmin = () => {
  const [token] = useLocalStorage('token');
  const decodedToken = decodeToken<Token>(token || '');

  return decodedToken && decodedToken.role.includes(Roles.Admin);
};
