import { Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FC } from 'react';

export const Error: FC<{
  error: string | AxiosError<any> | undefined | null;
  testId?: string;
}> = ({ error, testId }) => {
  if (!error) {
    return null;
  }
  return (
    <>
      {typeof error === 'string' ? (
        <Typography variant="body2" color="error" data-testid={testId}>
          {error ?? 'An unknown error has occurred'}
        </Typography>
      ) : error?.response?.data?.errors ? (
        Object.keys(error?.response?.data?.errors)?.map((key) => (
          <Typography
            variant="body2"
            color="error"
            key={key}
            data-testid={testId}
          >
            {error?.response?.data.errors[key] ??
              'An unknown error has occurred'}
          </Typography>
        ))
      ) : (
        <Typography variant="body2" color="error" data-testid={testId}>
          {error?.response?.data?.error?.message ??
            error?.message ??
            'An unknown error has occurred'}
        </Typography>
      )}
    </>
  );
};

/**
 * Parses error objects and returns an array of error messages.
 *
 * @param {string | AxiosError<any>} error - The error to parse.
 * @return {string[]} Array of error messages.
 */
export async function parseErrorAsync(
  error: string | AxiosError<any> | undefined | null
): Promise<string> {
  // If the error is undefined, return an empty array.
  if (!error) {
    return 'An unknown error has occurred';
  }
  // If the error is a string, return it as the only element in an array.
  if (typeof error === 'string') {
    return error || 'An unknown error has occurred';
  }

  if (
    error?.response?.data instanceof Blob &&
    error.response.headers['content-type'] === 'text/plain; charset=utf-8'
  ) {
    return await error?.response?.data?.text();
  }

  if (typeof error?.response?.data === 'string') {
    return error?.response?.data || 'An unknown error has occurred';
  }
  // If the error is an AxiosError and has a response with data errors, map them to an array.
  if (error?.response?.data?.errors) {
    // Map all the errors to one string.
    return Object.keys(error?.response?.data?.errors)
      ?.map((key) => error?.response?.data.errors[key] ?? 'error.unknown')
      ?.join(' ');
  }

  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  // Fallback to the error message or a default message.
  return error?.message ?? 'An unknown error has occurred';
}

export const parseError = (
  error: string | AxiosError<any> | undefined | null
): string => {
  // If the error is undefined, return an empty array.
  if (!error) {
    return 'An unknown error has occurred';
  }
  // If the error is a string, return it as the only element in an array.
  if (typeof error === 'string') {
    return error || 'An unknown error has occurred';
  }
  // If the error is an AxiosError and has a response with data errors, map them to an array.
  if (error?.response?.data) {
    const data = error.response.data?.errors ?? error.response.data;
    // Map all the errors to one string.
    let errorMessages = Object.keys(data)?.map((key) => data[key]);
    // Filter out any undefined or empty strings.
    errorMessages = errorMessages?.filter((message) => message);

    // If there are no errors, return the default message.
    if (!errorMessages?.length) {
      return 'An unknown error has occurred';
    }

    return errorMessages.join(' ');
  }
  // Fallback to the error message or a default message.
  return error?.message ?? 'An unknown error has occurred';
};
