import { useMediaQuery } from '@mui/material';
import useLocalStorage, { writeStorage } from '@rehooks/local-storage';

export const useTheme = (): {
  theme: string;
  setTheme: (theme: string) => void;
} => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [localStorageTheme] = useLocalStorage(
    'theme',
    prefersDarkMode ? 'dark' : 'light'
  );

  const theme = localStorageTheme === 'dark' ? 'dark' : 'light';
  const setTheme = (theme: string) => {
    writeStorage('theme', theme === 'dark' ? 'dark' : 'light');
  };
  return { theme, setTheme };
};
