import {
  LocalOffer as LocalOfferIcon,
  Update as UpdateIcon,
  Search as SearchIcon,
  Download as DownloadIcon,
  LocationOn as LocationOnIcon,
  Explore as ExploreIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  TextField,
  Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import useLocalStorage from '@rehooks/local-storage';
import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { UserContext } from '../../Contexts/UserContext';
import { httpClient } from '../../Interceptors/httpClient';
import { Link as RouterLink } from 'react-router-dom';

const Hero: React.FC = () => {
  return (
    <HeroContainer>
      <Container maxWidth="md">
        <Typography variant="h3" component="h1" gutterBottom>
          Discover the Best Local Happy Hours Near You
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          Find deals, real-time updates, and plan your perfect evening out.
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="#features"
            sx={{ mr: 2 }}
          >
            Explore Now
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            href="#contact"
          >
            Contact Us
          </Button>
        </Box>
      </Container>
    </HeroContainer>
  );
};

const HeroContainer = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/hero-image.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),
  textAlign: 'center',
}));

const Features: React.FC = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <LocalOfferIcon fontSize="large" color="primary" />,
      title: 'Comprehensive Listings',
      description:
        'Access a wide range of local happy hour deals across various venues.',
    },
    {
      icon: <UpdateIcon fontSize="large" color="primary" />,
      title: 'Real-Time Updates',
      description:
        'Stay informed about the latest happy hour specials and timings.',
    },
    {
      icon: <SearchIcon fontSize="large" color="primary" />,
      title: 'Easy Search and Filter',
      description:
        'Quickly find happy hours based on location, time, or type of establishment.',
    },
  ];

  return (
    <Box id="features" py={8} bgcolor={theme.palette.background.paper}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Our Features
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Discover what our platform offers to make your happy hour experience
          seamless.
        </Typography>
        <Grid container spacing={4} mt={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box textAlign="center" p={3}>
                {feature.icon}
                <Typography
                  variant="h6"
                  component="h3"
                  gutterBottom
                  color="textPrimary"
                >
                  {feature.title}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const HowItWorks: React.FC = () => {
  const theme = useTheme();

  const steps = [
    {
      icon: <DownloadIcon fontSize="large" color="primary" />,
      title: 'Browse Listings',
      description: 'Explore a variety of happy hour deals in your area.',
    },
    {
      icon: <LocationOnIcon fontSize="large" color="primary" />,
      title: 'Filter Your Search',
      description: 'Narrow down options based on location.',
    },
    {
      icon: <ExploreIcon fontSize="large" color="primary" />,
      title: 'Find Your Spot',
      description: 'Choose the perfect happy hour and plan your evening.',
    },
  ];

  return (
    <Box id="how-it-works" py={8} bgcolor={theme.palette.background.default}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          How It Works
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Simple steps to enjoy the best happy hours around you.
        </Typography>
        <Grid container spacing={4} mt={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box textAlign="center" p={3}>
                {step.icon}
                <Typography variant="h6" component="h3" gutterBottom>
                  {step.title}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const Contact: React.FC = () => {
  const theme = useTheme();
  const [token] = useLocalStorage('token');
  const { user } = useContext(UserContext);

  const [formData, setFormData] = useState<{
    name: string;
    email: string;
    message: string;
  }>({
    name: '',
    email: '',
    message: '',
  });
  const [alert, setAlert] = useState<{
    type: 'success' | 'error';
    message: string;
  }>({ type: 'success', message: '' });

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
        email: user.email || '',
      }));
    }
  }, [user]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formEndpoint = '/contactus';

    try {
      const response = await httpClient.post(formEndpoint, {
        name: formData.name,
        message: formData.message,
      });

      if (response.status === 200) {
        setAlert({ type: 'success', message: 'Message sent successfully!' });
        setFormData({ name: '', email: '', message: '' });
      } else {
        setAlert({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
        });
      }
    } catch (error) {
      setAlert({
        type: 'error',
        message: 'Network error. Please try again later.',
      });
    }
  };

  if (!token) {
    return (
      <Box id="contact" py={8} bgcolor={theme.palette.background.paper}>
        <Container maxWidth="sm">
          <Typography variant="h5" align="center" gutterBottom>
            Please log in to send us a message.
          </Typography>
          <Box textAlign="center" mt={4}>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/login"
            >
              Login
            </Button>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box id="contact" py={8} bgcolor={theme.palette.background.paper}>
      <Container maxWidth="sm">
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Get in Touch
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Have questions or feedback? We'd love to hear from you!
        </Typography>
        {alert.message && (
          <Alert severity={alert.type} sx={{ mt: 2 }}>
            {alert.message}
          </Alert>
        )}
        <Box component="form" mt={4} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                required
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Message"
                variant="outlined"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!formData.name || !formData.message}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.palette.background.paper} py={1}>
      <Container maxWidth="lg">
        <Box textAlign="center">
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Happy Hour Find
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Happy Hour Find | Discover Best Happy Hours Near You</title>
        <meta
          name="description"
          content="Find and explore the best local happy hour deals with Happy Hour Find. Discover top venues, real-time updates, and plan your perfect evening out."
        />
        <meta
          name="keywords"
          content="local happy hour, happy hour deals, find happy hours near me, real-time happy hour updates"
        />
        <link rel="canonical" href="https://happyhourfind.com/about" />
      </Helmet>
      <Hero />
      <Features />
      <HowItWorks />
      <Contact />
      <Footer />
    </>
  );
};
