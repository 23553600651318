import {
  Box,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';

export interface RestaurantCardSkeletonProps {
  amount: number;
  sx?: SxProps;
}

export const RestaurantCardSkeleton = (props: RestaurantCardSkeletonProps) => {
  const { amount, sx } = props;
  const skeletonArray = Array.from(Array(amount).keys());

  return (
    <Stack sx={sx}>
      {skeletonArray.map((value) => (
        <Card
          key={value}
          sx={{
            m: 1,
            width: 400,
            '@media (max-width:400px)': {
              width: 350,
            },
            animation: 'fadeIn 1s forwards',
            animationDelay: `${value * 0.1}s`,
            opacity: 0,
            '@keyframes fadeIn': {
              '0%': {
                opacity: 0,
              },
              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          <CardContent>
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Skeleton variant="text" width={210} height={32} />
                <Skeleton variant="circular" width={24} height={24} />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography
                  component="div"
                  variant="body2"
                  color="text.secondary"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 0.3,
                    }}
                  >
                    <Skeleton variant="text" width={100} height={15} />
                  </Box>
                  <Box
                    sx={{
                      mb: 1,
                      p: 0.3,
                      borderRadius: 1,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <Skeleton variant="circular" width={18} height={18} />
                      <Box sx={{ ml: 0.5, flexGrow: 1 }}>
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      p: 0.3,
                      borderRadius: 1,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <Skeleton variant="circular" width={18} height={18} />
                      <Box sx={{ ml: 0.5, flexGrow: 1 }}>
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            <Skeleton
              variant="rectangular"
              width={90}
              height={36}
              sx={{ ml: 1 }}
            />
          </CardActions>
          <CardActions>
            <Skeleton
              variant="rectangular"
              width={90}
              height={36}
              sx={{ ml: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={90}
              height={36}
              sx={{ ml: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={90}
              height={36}
              sx={{ ml: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={20}
              height={20}
              sx={{ ml: 'auto' }}
            />
          </CardActions>
        </Card>
      ))}
    </Stack>
  );
};
