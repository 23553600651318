import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  useTheme,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { alpha, styled } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Error } from '../../../Components/Error/Error';
import { httpClient } from '../../../Interceptors/httpClient';

const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await httpClient.post('/ZipCodes', formData);
  return response.data;
};

const DragOverOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: alpha(theme.palette.secondary.main, 0.2),
  borderRadius: '4px',
  zIndex: 2,
}));

export const ZipCode = () => {
  const [file, setFile] = useState<File>();
  const [isDragging, setIsDragging] = useState(false);
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragCounter, setDragCounter] = useState(0);
  const [open, setOpen] = useState(false);

  const mutation = useMutation({
    mutationFn: uploadFile,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setFile(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter((prevCounter) => prevCounter + 1);
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter((prevCounter) => prevCounter - 1);
    if (dragCounter === 1) {
      setIsDragging(false);
    }
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(0);
    setIsDragging(false);
  };

  const handleSubmit = async () => {
    if (file) {
      await mutation.mutateAsync(file);
      setFile(undefined);
      setOpen(true);
      // Reset the input value
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const handleDelete = () => {
    setFile(undefined);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    const handleGlobalDragLeave = (event: DragEvent) => {
      if (!event.relatedTarget) {
        setDragCounter(0);
        setIsDragging(false);
      }
    };

    document.addEventListener('dragleave', handleGlobalDragLeave);
    return () => {
      document.removeEventListener('dragleave', handleGlobalDragLeave);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: isDragging ? 'copy' : 'default',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            border: `1px dashed ${theme.palette.secondary.main}`,
            padding: '24px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'transform 0.2s',
            transform: isDragging ? 'scale(1.05)' : 'scale(1)',
          }}
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          role="region"
          aria-describedby="dropInstructions"
        >
          {isDragging && <DragOverOverlay />}
          <Box sx={{ mb: '8px' }}>
            <input
              ref={inputRef}
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              aria-label="File input"
            />
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="primary"
              disabled={mutation.isPending}
              onClick={handleClick}
            >
              {file ? 'Change' : 'Select'}
            </Button>
            <Button
              sx={{ marginRight: '8px' }}
              disabled={!file || mutation.isPaused}
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!file || mutation.isPending}
            >
              {mutation.isPending ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </Box>
          <Box sx={{ marginLeft: '8px', textAlign: 'center' }}>
            <Tooltip title="Drop file here" arrow>
              <span id="dropInstructions">
                {file
                  ? `File: ${file.name}`
                  : 'Drag and drop a file or click to select'}
              </span>
            </Tooltip>
          </Box>
        </Box>
        {/* Add an error message if the mutation fails */}
        {mutation.isError && (
          <Box sx={{ color: 'error.main', mt: '8px' }}>
            <Error error={mutation.error as AxiosError} />
          </Box>
        )}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="success">
          File uploaded successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};
