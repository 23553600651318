import React, { useContext } from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { LocationContext } from '../../Contexts/UserLocationContext';
import { httpClient } from '../../Interceptors/httpClient';
import { EventData } from '../../Models/Events/Event';
import { useQuery } from '@tanstack/react-query';
import { EventSkeletonGrid } from './EventSkeleton';
import { EventCard } from './EventCard';

const fetchEvents = async (latitude: number, longitude: number) => {
  const response = await httpClient.get<EventData[]>('/events', {
    params: { latitude, longitude },
  });
  return response.data;
};

export const Events: React.FC = () => {
  const {
    location,
    error: locationError,
    requestLocation,
  } = useContext(LocationContext);

  const {
    data: events,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['events', location?.latitude, location?.longitude],
    queryFn: () => fetchEvents(location!.latitude, location!.longitude),
    enabled: !!location,
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!location) {
      requestLocation();
    }
  }, [location, requestLocation]);

  if (locationError) {
    return (
      <Container maxWidth="md">
        <Box sx={{ p: 3 }}>
          <Typography color="error">Error: {locationError}</Typography>
        </Box>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Events Near You
          </Typography>
          <EventSkeletonGrid />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        maxWidth="md"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box sx={{ p: 3 }}>
          <Typography color="error">
            Failed to fetch events. Please try again later.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Events Near You
        </Typography>
        {events && events.length === 0 ? (
          <Typography>No events found in your area.</Typography>
        ) : (
          <Grid container spacing={2}>
            {events?.map((event) => (
              <Grid item xs={12} md={6} key={event.id}>
                <EventCard event={event} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};
