import DeleteIcon from '@mui/icons-material/Delete';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

const restaurantEllipsisActions = [
  { action: 'Delete', icon: <DeleteIcon /> },
  { action: 'Update Lat/Long', icon: <EditLocationAltIcon /> },
  { action: 'Update Happy Hour', icon: <LocalBarIcon /> },
  { action: 'Fetch New Restaurant Info', icon: <RestaurantIcon /> },
] as const;

const ITEM_HEIGHT = 48;

export interface RestaurantActionEllipsisProps {
  onAction: (
    action: (typeof restaurantEllipsisActions)[number]['action']
  ) => void;
  disabled?: boolean;
}
export function RestaurantActionEllipsis(
  props: RestaurantActionEllipsisProps
): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { onAction, disabled } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        disabled={disabled}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {restaurantEllipsisActions.map((option) => (
          <MenuItem
            disabled={disabled}
            key={option.action}
            onClick={() => {
              onAction(option.action);
              handleClose();
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: 250,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 2,
                }}
              >
                {option.icon}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {option.action}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
