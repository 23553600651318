import { createContext, useCallback, useState } from 'react';
import { httpClient } from '../Interceptors/httpClient';
import { LocationResponse } from '../Models/LocationResponse';

export const LocationContext = createContext({
  location: null as GeolocationCoordinates | null,
  error: null as string | null,
  loading: false,
  requestLocation: () => Promise.resolve(),
  setLocation: (location: GeolocationCoordinates | null) => {},
  setError: (error: string | null) => {},
  setLoading: (loading: boolean) => {},
});

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchLocationFromAPI = useCallback(() => {
    return httpClient
      .get<LocationResponse>('/Location')
      .then((response) => {
        setLocation({
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          accuracy: 0,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          speed: null,
        } as GeolocationCoordinates);
        setError(null); // Clear any previous errors
      })
      .catch((apiError) => {
        setError(apiError.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const requestLocation = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (navigator.geolocation) {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation(position.coords);
            setLoading(false);
            resolve();
          },
          () => {
            setError('Failed to retrieve geolocation.');
            fetchLocationFromAPI().then(resolve);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
        fetchLocationFromAPI().then(resolve);
      }
    });
  }, [fetchLocationFromAPI]);

  return (
    <LocationContext.Provider
      value={{
        location,
        error,
        loading,
        requestLocation,
        setLocation,
        setError,
        setLoading,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
