import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { writeStorage } from '@rehooks/local-storage';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Error } from '../../Components/Error/Error';
import { httpClient } from '../../Interceptors/httpClient';

export const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const validation = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setError(undefined);
      try {
        const response = await httpClient.post('/identity/login', {
          username: values.email,
          password: values.password,
        });
        if (response.status === 200) {
          writeStorage('token', response.data.token);
          writeStorage('refreshToken', response.data.refreshToken);
          navigate('/');
        }
      } catch (e: any) {
        setError(e ?? 'An unknown error occurred while logging in.');
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? '100%' : '50%',
          height: '100%',
          display: isSmallScreen ? 'none' : 'block',
        }}
      >
        <img
          alt="Depicts a lively, vibrant cityscape at sunset with silhouettes of people enjoying outdoor dining. The skyline is adorned with various skyscrapers, set against a dramatic sky with colorful sun rays radiating from the setting sun. In the foreground, groups of people are seated at tables, raising glasses and socializing under umbrellas, giving the scene a festive and relaxed atmosphere. The use of vivid colors like orange, yellow, blue, and red enhances the warm and celebratory ambiance of a city evening gathering."
          src="/city-sunset.webp"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        ></img>
      </Box>
      <Box
        component={'form'}
        sx={{
          m: 1,
          width: isSmallScreen ? '100%' : '50%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '250px',
            margin: 'auto',
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3">
            Login
          </Typography>
          <TextField
            label="Email"
            name="email"
            value={formik.values.email}
            variant="filled"
            onChange={formik.handleChange}
            margin="normal"
            disabled={formik.isSubmitting}
            error={!!formik.errors.email && formik.touched.email}
            helperText={
              !!formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ''
            }
            onBlur={formik.handleBlur}
            required
          />
          <TextField
            label="Password"
            name="password"
            value={formik.values.password}
            variant="filled"
            onChange={formik.handleChange}
            margin="normal"
            type="password"
            disabled={formik.isSubmitting}
            error={!!formik.errors.password && formik.touched.password}
            helperText={
              !!formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ''
            }
            onBlur={formik.handleBlur}
            required
          />
          {/* Disable button when any field is not valid, loading or has no value */}
          <Button
            sx={{
              marginBottom: '4px',
            }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            {formik.isSubmitting ? 'Loading...' : 'Login'}
          </Button>
          {error && <Error error={error} />}
          <Typography
            sx={{ textAlign: 'center' }}
            variant="body2"
            component="p"
          >
            <Box
              component="span"
              sx={{
                marginRight: '4px',
              }}
            >
              Don't have an account?
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
              }}
              component="span"
            >
              <RouterLink to="/signup" style={{ textDecoration: 'none' }}>
                <Box
                  component="span"
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Sign Up
                </Box>
              </RouterLink>
            </Box>
          </Typography>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="body2"
            component="p"
          >
            <RouterLink
              to="/forgot-password"
              style={{ textDecoration: 'none' }}
            >
              <Box
                component="span"
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Forgot Password?
              </Box>
            </RouterLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
