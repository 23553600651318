import { MapOutlined } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import { CircularProgress, Fab, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HappyHourSearch } from '../../Components/HappyHourSearch/HappyHourSearch';
import { RestaurantCard } from '../../Components/RestaurantCard/RestaurantCard';
import { RestaurantCardSkeleton } from '../../Components/RestaurantCardSkeleton/RestaurantCardSkeleton';
import { RestaurantMap } from '../../Components/RestaurantMap/RestaurantMap';
import { LocationContext } from '../../Contexts/UserLocationContext';
import { httpClient } from '../../Interceptors/httpClient';
import { Restaurant } from '../../Models/Restaurant/Restaurant';

export const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    location,
    error: locationError,
    requestLocation,
  } = useContext(LocationContext);
  const [zipCode, setZipCode] = useState<string | undefined>(
    searchParams.get('zipCode') || undefined
  );
  const [results] = useState<number>(Number(searchParams.get('results')) || 5);
  const [radius] = useState<number>(Number(searchParams.get('radius')) || 10);
  const [search, setSearch] = useState<string | undefined>(
    searchParams.get('search') || undefined
  );
  const [mapOpen, setMapOpen] = useState<boolean>(true);
  const [listOpen, setListOpen] = useState<boolean>(true);
  const happyHourCardsRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const [highlightedRestaurantId, setHighlightedRestaurantId] =
    useState<string>('');
  const [width, setWidth] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    if (!location && !zipCode) {
      requestLocation();
    }
  }, [location, zipCode, requestLocation]);

  useEffect(() => {
    if (!isSmallScreen) {
      setMapOpen(true);
      setListOpen(true);
    } else {
      setMapOpen(false);
      setListOpen(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    const handleResize = () => {
      if (componentRef?.current) {
        setWidth(componentRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsSmallScreen(width < 932);
  }, [width]);

  const fetchRestaurants = async ({ pageParam = 0 }) => {
    if ((!location?.latitude || !location?.longitude) && !zipCode) {
      return [];
    }
    const radiusToMeters = radius * 1609.34;
    const searchField = search ? `&search=${search}` : '';
    if (zipCode) {
      const restaurants = await httpClient.get<Restaurant[]>(
        `/restaurant/zip/${zipCode}/${radiusToMeters}/${results}?page=${pageParam}${searchField}`
      );
      return restaurants.data;
    }
    const restaurants = await httpClient.get<Restaurant[]>(
      `/restaurant/lat-long/${location?.latitude}/${location?.longitude}/${radiusToMeters}/${results}?page=${pageParam}${searchField}`
    );
    return restaurants.data;
  };

  const { data, isFetching, error, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['happyHours', location, zipCode, results, radius, search],
      queryFn: fetchRestaurants,
      getNextPageParam: (lastPage, pages): number | undefined => {
        if (lastPage.length === 0 || lastPage.length < results) {
          return undefined;
        }
        if (pages.length >= 10) {
          return undefined;
        }
        return pages.length;
      },
      initialPageParam: 0,
      enabled: !!location || !!zipCode,
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    refetch();
  }, [zipCode, results, radius, refetch]);
  const onScroll = async () => {
    const currentElement = happyHourCardsRef.current;
    if (!currentElement) {
      return;
    }
    const scrollPosition =
      currentElement.scrollTop + currentElement.clientHeight;
    const scrollHeight = currentElement.scrollHeight;

    const isAtBottom = scrollPosition >= scrollHeight - 1;
    if (isAtBottom && !isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const element = happyHourCardsRef.current;
    if (element) {
      element.addEventListener('scroll', onScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [happyHourCardsRef.current, isFetching, results]);

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });

    const currentElement = happyHourCardsRef.current;
    if (currentElement && (data?.pages?.length || 0) > 0) {
      if (currentElement.lastElementChild) {
        observerRef.current.observe(currentElement.lastElementChild);
      }
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [data, fetchNextPage, hasNextPage]);

  const updateSearchParams = (params: {
    zipCode?: string;
    results?: number;
    radius?: number;
    search?: string;
  }) => {
    const newParams = new URLSearchParams(searchParams.toString());
    if (params.results !== undefined) {
      newParams.set('results', params.results.toString());
    } else {
      newParams.delete('results');
    }
    if (params.radius !== undefined) {
      newParams.set('radius', params.radius.toString());
    } else {
      newParams.delete('radius');
    }
    if (params.search !== undefined) {
      newParams.set('search', params.search);
    } else {
      newParams.delete('search');
    }
    if (params.zipCode !== undefined) {
      newParams.set('zipCode', params.zipCode);
    } else {
      newParams.delete('zipCode');
      newParams.delete('results');
      newParams.delete('radius');
    }
    setSearchParams(newParams);
  };

  return !error ? (
    <Box
      ref={componentRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 1,
        height: '100%',
      }}
    >
      <HappyHourSearch
        sx={{
          mb: 1,
        }}
        location={location || undefined}
        onSearch={(search, zipCode) => {
          setZipCode(zipCode);
          setSearch(search);
          updateSearchParams({ zipCode, search });
        }}
        zipCode={zipCode}
      />
      <Fab
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 10000,
          display: isSmallScreen && !isFetching ? 'flex' : 'none',
        }}
        onClick={() => {
          if (!mapOpen) {
            setMapOpen(true);
            setListOpen(false);
          } else {
            setMapOpen(false);
            setListOpen(true);
          }
        }}
        color="primary"
        aria-label="add"
      >
        {!mapOpen ? <MapOutlined /> : <ListIcon />}
      </Fab>
      {(isFetching && !data) || (!locationError && !location && !zipCode) ? (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : '2fr 1fr',
            gap: 1,
            justifyItems: 'center',
            height: '100%',
          }}
        >
          {/* Restaurant Card Skeletons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {Array.from(new Array(results)).map((_, index) => (
              <RestaurantCardSkeleton
                key={index}
                amount={5}
                sx={{
                  animation: 'fadeIn 1s forwards',
                  animationDelay: `${index * 0.1}s`,
                  opacity: 0,
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                    },
                    '100%': {
                      opacity: 1,
                    },
                  },
                  height: 'auto',
                }}
              />
            ))}
          </Box>

          {/* Map Skeleton */}
          <Box>
            <Skeleton
              variant="rectangular"
              width={500}
              height="100%"
              sx={{
                width: isSmallScreen ? '100%' : 500,
                height: '100%',
                '@media (max-width:400px)': {
                  width: 350,
                  height: 350,
                },
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            height: 'calc(100% - 72px)',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          ></Box>
          {data?.pages[0].length === 0 ? (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                color: (theme) => theme.palette.text.secondary,
              }}
              variant="body1"
              gutterBottom
            >
              {location ? 'No happy hours found' : 'Please select a location'}
            </Typography>
          ) : (
            <>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isSmallScreen ? '1fr' : '2fr 1fr',
                  gap: 1,
                  height: '100%',
                  overflow: 'auto',
                  justifyItems: 'center',
                }}
              >
                <Box
                  ref={happyHourCardsRef}
                  sx={{
                    height: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: listOpen ? 'flex' : 'none',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {// Flatten the pages array and map over the restaurants
                  data?.pages.flat().map((restaurant, index) => (
                    <RestaurantCard
                      sx={{
                        animation: 'fadeIn 1s forwards',
                        animationDelay: `${index * 0.1}s`,
                        opacity: 0,
                        '@keyframes fadeIn': {
                          '0%': {
                            opacity: 0,
                          },
                          '100%': {
                            opacity: 1,
                          },
                        },
                        height: 'auto',
                      }}
                      location={location}
                      onMouseEnter={() =>
                        setHighlightedRestaurantId(restaurant.id)
                      }
                      onMouseLeave={() => setHighlightedRestaurantId('')}
                      key={restaurant.id}
                      restaurant={restaurant}
                    />
                  ))}
                </Box>
                {location && data && (
                  <Box
                    sx={{
                      position: mapOpen ? 'relative' : 'absolute',
                      top: mapOpen ? 0 : -10000,
                      width: isSmallScreen ? '100%' : 500,
                      height: '100%',
                      pt: 1,
                    }}
                  >
                    <RestaurantMap
                      highlightedRestaurantId={highlightedRestaurantId}
                      restaurants={data.pages.flat()}
                      location={location}
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {isFetching && <CircularProgress size={24} />}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
        variant="h4"
        component="h1"
        gutterBottom
        color="error"
      >
        Error
      </Typography>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
        variant="h6"
        component="h2"
        gutterBottom
        color="error"
      >
        {(error as AxiosError)?.message || locationError}
      </Typography>
    </Box>
  );
};
