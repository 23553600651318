import { Box, Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { RestaurantCard } from '../../Components/RestaurantCard/RestaurantCard';
import { RestaurantCardSkeleton } from '../../Components/RestaurantCardSkeleton/RestaurantCardSkeleton';
import { UserContext } from '../../Contexts/UserContext';
import { LocationContext } from '../../Contexts/UserLocationContext';

export const Favorites = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const { location } = useContext(LocationContext);

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <Grid container justifyContent="center" sx={{ height: '100%' }}>
        <Grid item xs={12} sm={12} md={12}>
          {(user?.favoriteRestaurants ?? [])?.length > 0 && (
            <Typography mt={2} color="textPrimary" variant="h5" align="center">
              Your favorites
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              height: '100%',
              overflow: 'auto',
              justifyContent: 'center',
            }}
          >
            {!user && <RestaurantCardSkeleton amount={5} />}
            {user?.favoriteRestaurants?.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  You don't have any favorite restaurants yet.
                </Typography>
              </Box>
            )}
            {user?.favoriteRestaurants?.map((restaurant, index) => (
              <RestaurantCard
                sx={{
                  animation: 'fadeIn 1s forwards',
                  animationDelay: `${index * 0.1}s`,
                  opacity: 0,
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                    },
                    '100%': {
                      opacity: 1,
                    },
                  },
                }}
                location={location}
                key={restaurant.id}
                restaurant={restaurant}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
