import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { httpClient } from '../../../Interceptors/httpClient';
import { ZipCodeAreaAutocompleteProps } from './ZipCodeAreaAutocompleteProps';
import { ZipCodeAreaAutocompleteRef } from './ZipCodeAreaAutocompleteRef';
import { ZipCodeResult } from './ZipCodeResult';
import { ZipCodeResultType } from './ZipCodeResultType';

export const ZipCodeAreaAutocomplete = forwardRef<
  ZipCodeAreaAutocompleteRef,
  ZipCodeAreaAutocompleteProps
>((props, ref) => {
  const { onSelect, sx } = props;

  const [value, setValue] = useState<ZipCodeResult | null>(null);
  const [inputValue, setInputValue] = useState('');
  const timeout = useRef<NodeJS.Timeout>();

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const {
    data: restaurants,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<ZipCodeResult[], AxiosError>({
    queryKey: ['zipCodes', inputValue],
    queryFn: async () => {
      if (!inputValue) {
        return [];
      }
      if (value) {
        return [value];
      }
      const encodedInput = encodeURIComponent(inputValue);

      const response = await httpClient.get<ZipCodeResult[]>(
        `/zipCodes/search-areas/${encodedInput}`
      );
      return response.data;
    },
    enabled: false,
  });

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: ZipCodeResult | null
  ) => {
    setValue(newValue);
    onSelect?.(newValue || null);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      refetch();
    }, 300);
  }, [inputValue, refetch]);

  useImperativeHandle(ref, () => ({
    clear: () => {
      setValue(null);
      setInputValue('');
    },
  }));

  return (
    <Autocomplete
      sx={{
        width: isSmallScreen ? '100%' : 250,
        ...sx,
      }}
      id={props.id}
      loading={isLoading || isFetching}
      value={value}
      size="small"
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={restaurants || []}
      renderOption={(props, option) => (
        <li {...props} key={option.value + option.resultType}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{option.value}</Typography>
            <Typography variant="caption">
              {option.resultType === ZipCodeResultType.State
                ? 'State'
                : option.resultType === ZipCodeResultType.County
                  ? 'County'
                  : 'Zip Code'}
            </Typography>
          </Box>
        </li>
      )}
      getOptionLabel={(option) =>
        option.value +
        (option.resultType === ZipCodeResultType.State
          ? ' (State)'
          : option.resultType === ZipCodeResultType.County
            ? ' (County)'
            : ' (Zip Code)')
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by area"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading || isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {!isLoading && !isFetching
                  ? params.InputProps.endAdornment
                  : null}
              </React.Fragment>
            ),
          }}
        />
      )}
      noOptionsText={inputValue ? 'No results found' : 'Start typing to search'}
      isOptionEqualToValue={(option, value) =>
        option.value + option.resultType === value?.value + value?.resultType
      }
    />
  );
});
