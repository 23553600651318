import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const Main: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
      }}
      component={'main'}
    >
      <Outlet />
    </Box>
  );
};
