import { useLocalStorage } from '@rehooks/local-storage';
import { FC } from 'react';
import { decodeToken } from 'react-jwt';
import { Navigate } from 'react-router-dom';

export interface ProtectedLoggedInRouteProps {
  redirectTo: string;
  children: any;
}

export const ProtectedLoggedInRoute: FC<ProtectedLoggedInRouteProps> = ({
  redirectTo = '/',
  children,
}) => {
  const [token] = useLocalStorage('token');
  const decodedToken = decodeToken(token || '');

  if (decodedToken) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};
