import { Box, Typography, useMediaQuery } from '@mui/material';

export const MilesAway = (props: { distance: number }) => {
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="caption" color="text.secondary">
        {props.distance.toFixed(1)} mi {isSmallScreen ? null : 'away'}
      </Typography>
    </Box>
  );
};
