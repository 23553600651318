import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { About } from './Pages/About/About';
import { Admin } from './Pages/Admin/Admin';
import { ChangePassword } from './Pages/ChangePassword/ChangePassword';
import { ContactUs } from './Pages/ContactUs/ContactUs';
import { Favorites } from './Pages/Favorites/Favorites';
import { ForgotPassword } from './Pages/ForgotPassword/ForgotPassword';
import { Home } from './Pages/Home/Home';
import { Login } from './Pages/Login/Login';
import { NotFound } from './Pages/NotFound/NotFound';
import { ResetPassword } from './Pages/ResetPassword/ResetPassword';
import { RestaurantPage } from './Pages/Restaurant/RestaurantPage';
import { SignUp } from './Pages/SignUp/Signup';
import { ProtectedAdminRoute } from './RouteGuards/ProtectedAdminRoute';
import { ProtectedLoggedInRoute } from './RouteGuards/ProtectedLoggedInRoute';
import { ProtectedLoggedOutRoute } from './RouteGuards/ProtectedLoggedOutRoute';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Events } from './Pages/Events/Events';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppWrapper />}>
          <Route path="/" element={<Home />} />
          <Route path="events" element={<Events />} />
          <Route path="about" element={<About />} />
          <Route path="restaurant/:restaurantId" element={<RestaurantPage />} />
          <Route
            path="favorites"
            element={
              <ProtectedLoggedOutRoute redirectTo="/">
                <Favorites />
              </ProtectedLoggedOutRoute>
            }
          />
          <Route
            path="signUp"
            element={
              <ProtectedLoggedInRoute redirectTo="/">
                <SignUp />
              </ProtectedLoggedInRoute>
            }
          />
          <Route
            path="login"
            element={
              <ProtectedLoggedInRoute redirectTo="/">
                <Login />
              </ProtectedLoggedInRoute>
            }
          />
          <Route
            path="forgot-password"
            element={
              <ProtectedLoggedInRoute redirectTo="/">
                <ForgotPassword />
              </ProtectedLoggedInRoute>
            }
          />
          <Route
            path="change-password"
            element={
              <ProtectedLoggedOutRoute redirectTo="/">
                <ChangePassword />
              </ProtectedLoggedOutRoute>
            }
          />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route
            path="contact-us"
            element={
              <ProtectedLoggedOutRoute redirectTo="/login">
                <ContactUs />
              </ProtectedLoggedOutRoute>
            }
          />
          <Route
            path="admin/*"
            element={
              <ProtectedAdminRoute redirectTo="/">
                <Admin />
              </ProtectedAdminRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
