import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Box, Typography } from '@mui/material';
import { RestaurantHappyHourProps } from './RestaurantHappyHourProps';

export const RestaurantHappyHour = (props: RestaurantHappyHourProps) => {
  const { restaurant, hideOverflow } = props;

  const renderHappyHourSection = (
    icon: JSX.Element,
    time: string | undefined,
    details: string | undefined
  ) => (
    <Box sx={{ mb: 1 }}>
      <Typography component="div" variant="body2" color="text.secondary">
        {(time || details) && (
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0.3 }}>
            {time && details && (
              <>
                <AccessTimeIcon sx={{ fontSize: 18, mr: 0.5 }} />
                <Box
                  sx={{
                    display: 'inline-block',
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {time}
                </Box>
              </>
            )}
            {!time && (
              <>
                <AccessTimeIcon sx={{ fontSize: 18, mr: 0.5 }} />
                <Box sx={{ flexGrow: 1, fontStyle: 'italic' }}>
                  Time not available
                </Box>
              </>
            )}
          </Box>
        )}
        {details && (
          <Box
            sx={{
              p: 0.3,
              borderRadius: 1,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              {icon}
              <Box
                sx={{
                  ml: 0.5,
                  flexGrow: 1,
                }}
              >
                {details.split('\n').map(
                  (line, index) =>
                    line.trim() && (
                      <Box
                        key={index}
                        sx={{
                          alignItems: hideOverflow ? 'center' : 'flex-start',
                          display: hideOverflow && index >= 3 ? 'none' : 'flex',
                        }}
                      >
                        <Box sx={{ mr: 0.5 }}>•</Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            overflow: 'hidden',
                            textOverflow: hideOverflow ? 'ellipsis' : 'clip',
                            whiteSpace: hideOverflow ? 'nowrap' : 'pre-wrap',
                          }}
                        >
                          {line}
                        </Box>
                      </Box>
                    )
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Typography>
    </Box>
  );

  return (
    <>
      {(restaurant.happyHourFoodTime || restaurant.happyHourFoodDetails) &&
        renderHappyHourSection(
          <RestaurantIcon sx={{ fontSize: 18 }} />,
          restaurant.happyHourFoodTime,
          restaurant.happyHourFoodDetails
        )}
      {(restaurant.happyHourDrinkTime || restaurant.happyHourDrinkDetails) &&
        renderHappyHourSection(
          <LocalBarIcon sx={{ fontSize: 18 }} />,
          restaurant.happyHourDrinkTime,
          restaurant.happyHourDrinkDetails
        )}
      {!restaurant.happyHourFoodTime &&
        !restaurant.happyHourFoodDetails &&
        !restaurant.happyHourDrinkTime &&
        !restaurant.happyHourDrinkDetails && (
          <Typography variant="body2" color="text.secondary">
            No happy hour information available
          </Typography>
        )}
    </>
  );
};
