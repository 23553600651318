import { Box, Button, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Error } from '../../Components/Error/Error';
import { UserContext } from '../../Contexts/UserContext';
import { httpClient } from '../../Interceptors/httpClient';

export const ContactUs = () => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: `${user?.firstName || ''} ${user?.lastName || ''}`,
      email: user?.email || '',

      message: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      message: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values) => {
      setError(undefined);
      try {
        const response = await httpClient.post('/contactUs', values);
        if (response.status === 200) {
          setSuccess(true);
        }
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          setError(e);
        } else {
          setError(undefined);
        }
      }
    },
  });

  if (success) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3">
          Thank you for contacting us!
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Typography sx={{ textAlign: 'center' }} variant="h3" component="h3">
          Contact Us
        </Typography>
        <TextField
          id="name"
          name="name"
          label="Name"
          variant="filled"
          margin="normal"
          value={formik.values.name}
          disabled={!!user?.firstName && !!user?.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          variant="filled"
          margin="normal"
          value={formik.values.email}
          disabled={true}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          sx={{ mb: 2 }}
          id="message"
          name="message"
          label="Message"
          variant="filled"
          margin="normal"
          multiline
          minRows={4}
          maxRows={10}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
        <Button
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          color="primary"
          variant="contained"
          type="submit"
        >
          {formik.isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
        {error && (
          <Box sx={{ mt: 1 }}>
            <Error error={error} />
          </Box>
        )}
      </form>
    </Box>
  );
};
