import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Chip,
} from '@mui/material';
import { LocationOn, AccessTime, AttachMoney } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import { EventData } from '../../Models/Events/Event';

interface EventCardProps {
  event: EventData;
}

export const EventCard: React.FC<EventCardProps> = ({ event }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {event.images && event.images.length > 0 && (
        <CardMedia
          component="img"
          height="200"
          image={event.images[0].url}
          alt={event.name}
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          {event.name}
        </Typography>
        {event.dates?.start?.dateTime && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <AccessTime sx={{ mr: 1 }} />
            <Typography color="text.secondary">
              {new Date(event.dates.start.dateTime).toLocaleString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })}
            </Typography>
          </Box>
        )}
        {event._embedded?.venues?.[0] && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LocationOn sx={{ mr: 1 }} />
            <Typography color="text.secondary">
              {event._embedded.venues[0].name}
              {event._embedded.venues[0].city?.name &&
                `, ${event._embedded.venues[0].city.name}`}
            </Typography>
          </Box>
        )}
        {event.priceRanges && event.priceRanges.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <AttachMoney sx={{ mr: 1 }} />
            <Typography color="text.secondary">
              {`$${event.priceRanges[0].min} - $${event.priceRanges[0].max}`}
            </Typography>
          </Box>
        )}
        {event.classifications && event.classifications.length > 0 && (
          <Box sx={{ mb: 1 }}>
            {event.classifications[0]?.segment?.name && (
              <Chip
                label={event.classifications[0].segment.name}
                size="small"
                sx={{ mr: 1 }}
              />
            )}
            {event.classifications[0]?.genre?.name && (
              <Chip label={event.classifications[0].genre.name} size="small" />
            )}
          </Box>
        )}
        {event.description ? (
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(event.description),
            }}
            sx={{ mb: 2 }}
          />
        ) : (
          <Typography variant="body2" sx={{ mb: 2 }}>
            No description available.
          </Typography>
        )}
        {event.url && (
          <Button
            variant="contained"
            color="primary"
            href={event.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Tickets
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
