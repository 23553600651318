import React from 'react';
import { Box, Card, CardContent, Skeleton, Grid } from '@mui/material';

export const EventSkeleton: React.FC = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <Skeleton variant="rectangular" height={200} />
    <CardContent sx={{ flexGrow: 1 }}>
      <Skeleton variant="text" height={32} width="60%" sx={{ mb: 1 }} />
      <Skeleton variant="text" height={24} width="40%" sx={{ mb: 1 }} />
      <Skeleton variant="text" height={24} width="70%" sx={{ mb: 1 }} />
      <Skeleton variant="text" height={24} width="50%" sx={{ mb: 1 }} />
      <Box sx={{ mb: 1 }}>
        <Skeleton
          variant="rectangular"
          width={60}
          height={24}
          sx={{ mr: 1, display: 'inline-block' }}
        />
        <Skeleton
          variant="rectangular"
          width={60}
          height={24}
          sx={{ display: 'inline-block' }}
        />
      </Box>
      <Skeleton variant="text" height={80} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width={120} height={36} />
    </CardContent>
  </Card>
);

export const EventSkeletonGrid: React.FC = () => (
  <Grid container spacing={2}>
    {[...Array(4)].map((_, index) => (
      <Grid item xs={12} md={6} key={index}>
        <EventSkeleton />
      </Grid>
    ))}
  </Grid>
);
